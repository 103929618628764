import React from "react"
import styled from "styled-components";

const FooterWrapper = styled.div`
  background-color: #000000;
  color: #ffffff;
  padding: 0 4vw;
  position: relative;
  padding-top: 20px;
  padding-bottom: 60px;
`

const Footer = styled.div`
  margin: 0 auto;
  max-width: 1040px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255,255,255,0.7);

  @media (max-width: 690px) {
    flex-direction: column;
  }
`

const NavLinks = styled.nav`
  display: flex;
  color: rgba(255,255,255,0.7);

  a {
    position: relative;
    margin-left: 20px;
    color: rgba(255,255,255,0.7);
  }

  a:not(:first-child):before {
    content: '';
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #ffffff;
    border-radius: 100%;
  }
`

export default (props) => (
  <FooterWrapper>
    <Footer>
      <section>&copy; Richard Kendall 2001-{new Date().getFullYear()}.</section>
      <NavLinks>
        <a href="/">Latest Posts</a>
        <a href="https://github.com/richardjkendall/">Github</a>
        <a href="https://www.linkedin.com/in/richardjk/">LinkedIn</a>
        <a href="https://twitter.com/richardjkendall/">Twitter</a>
      </NavLinks>
    </Footer>
  </FooterWrapper>
)