import React from "react"
import styled from "styled-components";

const HeaderWrapper = styled.div`
  width: 100vw;
  background-color: #000000;
  padding: 0 4vw;
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
`

const SmallHeaderWrapper = styled.div`
  padding: 0 4vw;
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #ffffff;
  background: #000000 no-repeat center center;
  background-size: cover;
`

const HeaderColumn = styled.div`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10vw 4vw;
  min-height: 200px;
  max-height: 450px;
  text-align: center;
  color: #ffffff;
`

const BigHeaderText = styled.h1`
  z-index: 10;
  margin: 0;
  padding: 0;
  font-size: 2.0rem;
  font-weight: 600;
  color: #ffffff;
`

const SmallHeaderText = styled.h2`
  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: #ffffff;
  opacity: 0.6;
`

const Nav = styled.div`
  z-index: 300;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: hidden;
  height: 40px;
  font-size: 0.8rem;
  &:not(.small) {
    @media (min-width: 900px) {
      position:relative;
      top: -70px;
    }
  }
`

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: 10px;
  padding-bottom: 80px;
  letter-spacing: 0.4px;
  white-space: nowrap;
`

const NavLinkList = styled.ul`
  display: flex;
  margin: 0 0 0 -12px;
  padding: 0;
  list-style: none;

  li {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    line-height: 1.6em;

    a {
      display: block;
      margin: 0;
      padding: 10px 12px;
      color: #ffffff;
      opacity: 0.8;
    }
  }
`

const NavRightLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 40px;
`

const NavRightLinkPictures = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10px;
    color: #ffffff;
    opacity: 0.8;
  }
`

export default (props) => (
  <div>
    {!props.small && 
    <HeaderWrapper>
      <HeaderColumn>
        <HeaderText>
          <BigHeaderText>Richard's Blog</BigHeaderText>
          <SmallHeaderText>Changing the world, one byte at a time</SmallHeaderText>
        </HeaderText>
        <Nav>
          <NavLinks>
            <NavLinkList>
              <li><a href="/">Home</a></li>
              <li><a href="/about/">About</a></li>
              <li><a href="/contact/">Contact</a></li>
            </NavLinkList>
          </NavLinks>
          <NavRightLinks>
            <NavRightLinkPictures>
              <a href="https://www.linkedin.com/in/richardjk">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 510 510" style={{height: "1rem", fill: "#ffffff"}}>
                  <path d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z
                    M153,433.5H76.5V204H153V433.5z M114.75,160.65c-25.5,0-45.9-20.4-45.9-45.9s20.4-45.9,45.9-45.9s45.9,20.4,45.9,45.9
                    S140.25,160.65,114.75,160.65z M433.5,433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25,17.851-38.25,38.25V433.5H204
                    V204h76.5v30.6c12.75-20.4,40.8-35.7,63.75-35.7c48.45,0,89.25,40.8,89.25,89.25V433.5z"/>
                </svg>
              </a>
              <a href="https://twitter.com/richardjkendall">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{height: "1rem", fill: "#ffffff"}}><path d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z"></path></svg>
              </a>
              <a href="https://github.com/richardjkendall/">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style={{height: "1rem", fill: "#ffffff"}}><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"/></svg>
              </a>
            </NavRightLinkPictures>
          </NavRightLinks>
        </Nav>
      </HeaderColumn>
    </HeaderWrapper>}
    {props.small && 
    <SmallHeaderWrapper>
      <HeaderColumn>
        <Nav className="small">
          <NavLinks>
            <NavLinkList>
              <li><a href="/">Home</a></li>
              <li><a href="/about/">About</a></li>
              <li><a href="/contact/">Contact</a></li>
            </NavLinkList>
          </NavLinks>
          <NavRightLinks>
            <NavRightLinkPictures>
              <a href="https://www.linkedin.com/in/richardjk">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 510 510" style={{height: "1rem", fill: "#ffffff"}}>
                  <path d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z
                    M153,433.5H76.5V204H153V433.5z M114.75,160.65c-25.5,0-45.9-20.4-45.9-45.9s20.4-45.9,45.9-45.9s45.9,20.4,45.9,45.9
                    S140.25,160.65,114.75,160.65z M433.5,433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25,17.851-38.25,38.25V433.5H204
                    V204h76.5v30.6c12.75-20.4,40.8-35.7,63.75-35.7c48.45,0,89.25,40.8,89.25,89.25V433.5z"/>
                </svg>
              </a>
              <a href="https://twitter.com/richardjkendall">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{height: "1rem", fill: "#ffffff"}}><path d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z"></path></svg>
              </a>
              <a href="https://github.com/richardjkendall/">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style={{height: "1rem", fill: "#ffffff"}}><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"/></svg>
              </a>
            </NavRightLinkPictures>
          </NavRightLinks>
        </Nav>
      </HeaderColumn>  
    </SmallHeaderWrapper>}
  </div>
)
